import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { merge, Subject } from 'rxjs';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	providedIn: 'root'
})
export class TeamListingsService {

	private teamCache: { [id: string]: any; } = {};
	private teamList: any = [];
	public readonly dirty$: Subject<void> = new Subject();

	constructor(
		private http: HttpClient,
		private UserContext: UserContextService,
		PushBus: PushBus
	) {
		const accountId = UserContext.getAccount().id;
		merge(
			UserContext.userIdChanged$,
			PushBus.getRxJsObservable(accountId, '', ['TeamsUpdated'])
		).subscribe(() => {
			this.dirty$.next(null);
			this.flushTeamsCache();
		});
	}

	public get teamData(): any[] {
		return this.teamList;
	}

	public listTeams(isRefresh?: boolean): Promise<any[]> {
		if (isRefresh) {
			this.flushTeamsCache();
		}

		const accountId = this.UserContext.getAccount().id;
		let promise: Promise<any> = this.teamCache[accountId];

		if (promise) {
			return promise;
		}

		promise = lastValueFrom(this.http.get('/network/teams'))
			.then(data => this.teamList = data);

		this.teamCache[accountId] = promise;
		window.setTimeout(() => this.teamCache[accountId] = undefined, 60000);

		return promise;
	}

	public loadTeam(teamId: string): Promise<any> {
		return lastValueFrom(this.http.get(`/network/teams/${teamId}/info`));
	}

	public flushTeamsCache(): void {
		this.teamCache = {};
	}
}
