import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, WritableSignal, forwardRef, inject, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { RevIQService } from 'rev-shared/revIQ/RevIQ.Service';
import { TranscriptionLanguagesService } from 'rev-shared/media/transcription/TranscriptionLanguages.Service';
import { VbDropdownMenuModule } from 'rev-shared/ui/dropdown-menu/VbDropdownMenu.Module';

import { GenerateMetadataOption } from './VideoAiMetadataTypes';
import { getPreferredTranscribeLanguage } from './TranscribeLanguagePreference';

import styles from './VideoTranscribeAndAiMetadata.Component.module.less';

let videoTAiIndex = 0;

interface ITranscribeAndAiMetadata {
	transcribeLanguageId: string;
	metadataGenerationFields?: GenerateMetadataOption[]
}

interface ITranscribe {
	isLocked?: boolean,
	isEnabled?: boolean,
	languageCode?: string,
	languageName?: string,
	isNotPermitted?: boolean
}

interface IMetadataFields {
	[GenerateMetadataOption.TITLE]?: boolean;
	[GenerateMetadataOption.DESCRIPTION]?: boolean;
	[GenerateMetadataOption.TAGS]?: boolean;
	[GenerateMetadataOption.CHAPTERS]?: boolean;
}

interface IAiMetadata {
	isDisabled: boolean;
	all?: WritableSignal<boolean>;
	fields?: WritableSignal<IMetadataFields>;
	isNotPermitted?: boolean;
	expand?: boolean;
}

@Component({
	selector: 'video-transcribe-and-ai-metadata',
	standalone: true,
	imports: [
		BsDropdownModule,
		ButtonsAngularModule,
		VbDropdownMenuModule,
		CommonModule,
		FormsModule,
		TooltipModule,
		TranslateModule
	],
	templateUrl: './VideoTranscribeAndAiMetadata.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VideoTranscribeAndAiMetadata),
			multi: true
		}
	],
})
export class VideoTranscribeAndAiMetadata extends BaseControlValueAccessor<ITranscribeAndAiMetadata> implements OnInit {
	@Input({ required: true }) public accountId: string;
	@Input({ required: true }) public mediaFeatures: IMediaFeatures;
	@Input() public containerClass: { dropdownMenu: string };

	private TranscriptionLanguagesService = inject(TranscriptionLanguagesService);
	private RevIQService = inject(RevIQService);
	private MediaFeatures = inject(MediaFeaturesService);

	public availableLanguages;
	public videoTAiIndex = 0;
	public readonly styles = styles;
	public status: { [key: string]: boolean } = {};
	public revIQEligible = false;
	public transcribe: ITranscribe;
	public aiMetadata: IAiMetadata = {
		isDisabled: false,
		all: signal(false),
		fields: signal({}),
		expand: false,
	};

	public ngOnInit(): void {
		this.videoTAiIndex = ++videoTAiIndex;
		this.init();
	}

	public onTranscribeLanguageClick(lang: {code: string, name: string }): void {
		this.transcribe = {
			...this.transcribe,
			languageCode: lang.code,
			languageName: lang.name
		};
		this.setAiMetadata(this.transcribe);

		this.onValueChange(this.transcribe.languageCode, this.getSelectedMetadataFields());
	}

	public onTranscribeOptionChange($event: boolean): void {
		this.setAiMetadata(this.transcribe);
		this.onValueChange(this.transcribe.isEnabled ? this.transcribe.languageCode : undefined, this.getSelectedMetadataFields());
	}

	private init(): void {
		if (!this.mediaFeatures.transcriptionSettings.isEnabledRevTranscription) {
			return;
		}

		this.status = { initializing: true };
		Promise.all([
			this.RevIQService.isRevIQEligibleWithValidAiCredits(this.accountId),
			this.TranscriptionLanguagesService.loadAiServiceLanguages()
		])
			.then(([isRevIQEligible, languages]) => {
				if (!isRevIQEligible) {
					this.transcribe = {
						isLocked: true,
						isEnabled: false,
						isNotPermitted: true
					};
					this.setAiMetadata(this.transcribe);
				} else {
					this.availableLanguages = languages;
					this.initTranscribe();
					this.setAiMetadata(this.transcribe);
					if (this.transcribe.isEnabled) {
						this.onValueChange(this.transcribe.languageCode, this.getSelectedMetadataFields());
					}
				}
				this.status = { ready: true };
			});
	}

	private initTranscribe(): void {
		const language = getPreferredTranscribeLanguage(this.availableLanguages);
		const autoTranscribe = this.mediaFeatures.transcriptionSettings?.autoTranscribeOtherUploads;

		this.transcribe = {
			isLocked: autoTranscribe,
			isEnabled: autoTranscribe,
			languageCode: language.code,
			languageName: language.name
		};
	}

	private getSelectedMetadataFields(): GenerateMetadataOption[] {
		return Object.entries(this.aiMetadata.fields())
			.filter(([_, value]) => value)
			.map(([key]) => key as GenerateMetadataOption);
	}

	private setAiMetadata(transcribe: ITranscribe): void {
		if (!this.mediaFeatures.aiSettings.enableVideoMetadataGeneration) {
			this.aiMetadata.isNotPermitted = true;
			return;
		}

		const isTranscribeNotSupported = !transcribe.isEnabled || transcribe.languageCode !== 'en';
		this.aiMetadata.isDisabled = isTranscribeNotSupported;
		// metadata toggles must always be manually enabled by user
		this.updateAllMetadataFields(false, false, true);
	}

	private onValueChange(transcribeLanguageId?: string, metadataGenerationFields?: GenerateMetadataOption[]): void {
		this.updateModelValue({ transcribeLanguageId, metadataGenerationFields });
	}

	public updateAllMetadataFields(value: boolean, ignoreFields?: boolean, ignoreValueChange?: boolean): void {
		this.aiMetadata.all.set(value);

		if(!ignoreFields) {
			this.aiMetadata.fields.set({
				[GenerateMetadataOption.TITLE]: value,
				[GenerateMetadataOption.DESCRIPTION]: value,
				[GenerateMetadataOption.TAGS]: value,
				[GenerateMetadataOption.CHAPTERS]: value
			});
		}

		if (!ignoreValueChange) {
			this.onValueChange(this.transcribe.languageCode, this.getSelectedMetadataFields());
		}
	}

	public updateMetadataField(field: GenerateMetadataOption, value: boolean): void {
		this.aiMetadata.fields.update(fields => ({
			...fields,
			[field]: value
		}));

		const allChecked = Object.values(this.aiMetadata.fields()).every(Boolean);

		this.updateAllMetadataFields(allChecked, true, true);

		this.onValueChange(this.transcribe.languageCode, this.getSelectedMetadataFields());

	}
}
