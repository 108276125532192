import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { defaultColors, defaultFont, defaultHeaderColors, defaultLogo } from './Defaults';

import {
	BrandingSettings,
	FeaturedVideosSettings,
	HomepageTemplateType,
	ThemeSettings
} from 'rev-portal/branding/BrandingSettings.Contract';

import { AppBootstrapService } from 'rev-shared/bootstrap/AppBootstrap.Service';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { UploadService } from 'rev-shared/media/Upload.Service';
import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { HomepageSettings } from './BrandingSettings.Contract';
import { FeaturedCarouselType } from 'rev-portal/branding/FeaturedCarouselType';


@Injectable({
	providedIn: 'root'
})
export class BrandingService {
	constructor(
		private AppBootstrapService: AppBootstrapService,
		private UserContext: UserContextService,
		private UploadService: UploadService,
		private PushService: PushService,
		private http: HttpClient
	) {}

	public getAppBranding() : Promise<BrandingSettings> {
		const url = BootstrapContext.webcastId ?
			`/scheduled-events/${BootstrapContext.webcastId}/branding-settings` :
			`/network/accounts/${this.UserContext.getAccount().id}/branding-settings`;

		return lastValueFrom(this.http.get<BrandingSettings>(url))
			.then(settings => this.readBrandingSettings(settings));
	}

	public getBranding(accountId: string, allTeams: boolean): Promise<BrandingSettings> {
		return lastValueFrom(this.http.get<BrandingSettings>(`/network/accounts/${accountId}/branding-settings`, {
			params: {
				accountId,
				allTeams: '' + allTeams
			}
		}))
			.then(settings => this.readBrandingSettings(settings));
	}

	public saveBranding(accountId: string, brandingSettings: BrandingSettings): Promise<void>{
		return this.PushService.dispatchCommand('network:SaveBrandingSettings', {
			accountId,
			themeSettings: this.getThemeSettings(brandingSettings.themeSettings),
			headerSettings: brandingSettings.headerSettings,
			featuredVideosSettings: this.getFeaturedVideoSettings(brandingSettings.featuredVideosSettings),
			homepageSettings: brandingSettings.homepageSettings
		});
	}

	public readBrandingSettings(settings: BrandingSettings): BrandingSettings {

		const theme = settings.themeSettings;
		const header = settings.headerSettings;
		const homepage = settings.homepageSettings || {} as HomepageSettings;
		homepage.homepageTemplateType = homepage?.homepageTemplateType || HomepageTemplateType.Classic;

		const thumbnailCarouselSettings = settings.featuredVideosSettings.featuredCarouselType === FeaturedCarouselType.Thumbnail
			? settings.featuredVideosSettings?.thumbnailCarouselSettings : undefined;
		if (thumbnailCarouselSettings) {
			thumbnailCarouselSettings.showOverlay = !!thumbnailCarouselSettings.overlayColor;
		}

		return {
			...settings,
			themeSettings: {
				...theme,
				fontUri: theme.fontUri || defaultFont.url,
				logoUri: theme.logoUri || defaultLogo[this.AppBootstrapService.appConfig?.defaultLogoUriKey] || defaultLogo.url,
				accentColor: theme.accentColor || defaultColors.accentColor,
				accentFontColor: theme.accentFontColor || defaultColors.accentFontColor,
				primaryColor: theme.primaryColor || defaultColors.primaryColor,
				primaryFontColor: theme.primaryFontColor || defaultColors.primaryFontColor
			},
			headerSettings: {
				...header,
				backgroundColor: header.backgroundColor || defaultHeaderColors.background,
				fontColor: header.fontColor || defaultHeaderColors.font
			},
			homepageSettings: {
				...homepage,
				homepageTemplateType: homepage?.homepageTemplateType || HomepageTemplateType.Classic
			},
			featuredVideosSettings: {
				...settings.featuredVideosSettings,
				sortFeaturedPlaylistFirst: settings.featuredVideosSettings.sortFeaturedPlaylistFirst || false,
				thumbnailCarouselSettings: thumbnailCarouselSettings
					? { ...thumbnailCarouselSettings, showOverlay: !!thumbnailCarouselSettings.overlayColor }
					: undefined
			},
			isLoaded: true
		};
	}

	private getThemeSettings(themeSettings: ThemeSettings): ThemeSettings {
		return {
			accentColor: themeSettings.accentColor,
			accentFontColor: themeSettings.accentFontColor,
			fontFileName: themeSettings.fontFileName,
			fontId: themeSettings.fontId,
			logoImageId: themeSettings.logoImageId,
			primaryColor: themeSettings.primaryColor,
			primaryFontColor: themeSettings.primaryFontColor
		};
	}

	private getFeaturedVideoSettings(featuredVideoSettings: FeaturedVideosSettings): FeaturedVideosSettings {
		return {
			featuredContent: featuredVideoSettings.featuredContent,
			autoscrollDelay: featuredVideoSettings.autoscrollDelay,
			backgroundImageId: featuredVideoSettings.backgroundImageId,
			backgroundImageOpacity: featuredVideoSettings.backgroundImageOpacity,
			featuredCarouselType: featuredVideoSettings.featuredCarouselType,
			sortFeaturedPlaylistFirst: featuredVideoSettings.sortFeaturedPlaylistFirst,
			thumbnailCarouselSettings: featuredVideoSettings.featuredCarouselType === FeaturedCarouselType.Thumbnail ? featuredVideoSettings.thumbnailCarouselSettings : undefined
		};
	}

	public uploadLogo(file: FileWrapper): Promise<string> {
		return this.UploadService.uploadImage('AccountLogo', file)
			.then(result => result.message.imageId);
	}

	public uploadFont(file: FileWrapper): Promise<string> {
		return this.getFontUploadUri()
			.then(result =>
				this.UploadService.uploadFile(file, result, 'BrandingFontUploadFinished', 'BrandingFontUploadFailed')
					.then(() => result.id));
	}

	public uploadBackgroundImage(file: FileWrapper): Promise<string> {
		return this.UploadService.uploadImage('FeaturedVideosBackground', file)
			.then(result => result.message.imageId);
	}

	private getFontUploadUri(): Promise<any> {
		return this.PushService.dispatchCommand('network:AddBrandingFontToAccount', {}, 'BrandingFontAddedToAccount')
			.then(result => ({
				id: result.message.fontId,
				uploadUri: result.message.uploadUri
			}));
	}
}
