<vb-title [title]="'Login_UserAgreement' | translate"></vb-title>

<vb-auth-layout>

	<div [hidden]="!status.processing">
		<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
		</vb-loading-spinner>
	</div>

	<div [hidden]="!status.active" [ngClass]="authLayoutStyles.authForm">

		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>

		<div [ngClass]="authLayoutStyles.heading">
			<h2>{{ 'Login_UserAgreement' | translate }}</h2>
		</div>

		<p [ngClass]="authLayoutStyles.authCustomText" class="util-white-space-pre-wrap vb-scrollbar">{{ userAgreementMessage }}</p>

		<div class="margin-top-30">
			<button type="button" [ngClass]="authLayoutStyles.authBtn" (click)="accept()" vbBtnPrimary>
				{{ 'Agree' | translate }}
			</button>

			<button type="button" class="margin-top-10" [ngClass]="authLayoutStyles.authBtn" (click)="decline()" vbBtnSecondary>
				{{ 'Decline' | translate }}
			</button>
		</div>

	</div>

</vb-auth-layout>
