//portal related states
export const PORTAL_ROOT_STATE = `portal`;

export const PORTAL_DASHBOARD_STATE = `${PORTAL_ROOT_STATE}.dashboard`;
export const PORTAL_ADMIN_LOADER_STATE = `${PORTAL_ROOT_STATE}.load-admin`;
export const PORTAL_ADMIN_STATE = `${PORTAL_ROOT_STATE}.admin`;

export const PORTAL_MEDIA_STATE = `${PORTAL_ROOT_STATE}.media`;
export const PORTAL_MEDIA_ALL_STATE = `${PORTAL_MEDIA_STATE}.all`;
export const PORTAL_MEDIA_UPLOADS_STATE = `${PORTAL_MEDIA_STATE}.uploads`;
export const PORTAL_MEDIA_SEARCH_STATE = `${PORTAL_MEDIA_STATE}.search`;
export const PORTAL_MEDIA_BROWSE_STATE = `${PORTAL_MEDIA_STATE}.browse`;
export const PORTAL_MEDIA_CATEGORY_DETAILS_STATE = `${PORTAL_MEDIA_STATE}.category-detail`;
export const PORTAL_MEDIA_PENDING_VIDEOS_STATE = `${PORTAL_MEDIA_STATE}.pending-videos`;
export const PORTAL_MEDIA_EXPIRATIONS_STATE = `${PORTAL_MEDIA_STATE}.expirations`;
export const PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE = `${PORTAL_MEDIA_STATE}.my-subscriptions`;
export const PORTAL_MEDIA_EDIT_STATE = `${PORTAL_MEDIA_STATE}.edit`;
export const PORTAL_MEDIA_EDIT_BULK_EDIT_TRANSCRIPTIONMETADATA_SIDEBAR_STATE = `${PORTAL_MEDIA_EDIT_STATE}.bulk-edit-transcription-metadata-sidebar`;
export const PORTAL_MEDIA_EDIT_BULK_EDIT_SIDEBAR_STATE = `${PORTAL_MEDIA_EDIT_STATE}.bulk-edit-sidebar`;
export const PORTAL_MEDIA_DUMMY_STATE = `${PORTAL_MEDIA_STATE}.dummy`;

export const PORTAL_VIDEO_PLAYBACK_STATE: string = `${PORTAL_ROOT_STATE}.video`;
export const PUBLIC_VIDEO_PASSWORD_STATE = `${PORTAL_ROOT_STATE}.public-video-password`;
export const PORTAL_VIDEO_PLAYLIST_PLAYBACK_STATE: string = `${PORTAL_ROOT_STATE}.video-playlist-playback`;

export const PORTAL_VIDEO_SETTINGS_STATE = `${PORTAL_ROOT_STATE}.video-settings`;
export const PORTAL_VIDEO_EDIT_STATE = `${PORTAL_ROOT_STATE}.edit-video`;

export const PORTAL_USER_PROFILE = `${PORTAL_ROOT_STATE}.userProfile`;
export const PORTAL_USER_NOTIFICATION = `${PORTAL_ROOT_STATE}.user-notifications`;

export const PORTAL_TEAM_STATE = `${PORTAL_ROOT_STATE}.team`;
export const PORTAL_TEAM_ALL_STATE = `${PORTAL_ROOT_STATE}.team.all`;
export const PORTAL_TEAM_EDIT_STATE = `${PORTAL_ROOT_STATE}.team.edit`;

export const PORTAL_UPLOAD_STATE = `${PORTAL_ROOT_STATE}.upload`;
export const PORTAL_UPLOAD_ADD_STATE = `${PORTAL_UPLOAD_STATE}.add`;

//playlist
export const PLAYLIST_ROOT_STATE = `${PORTAL_MEDIA_STATE}.playlist`;
export const MY_PLAYLIST_STATE = `${PLAYLIST_ROOT_STATE}.my-playlists`;
export const PLAYLIST_DETAIL_STATE = `${PLAYLIST_ROOT_STATE}.playlist-detail`;
export const FEATURED_PLAYLIST_DETAIL_STATE = `${PLAYLIST_ROOT_STATE}.featured-playlist`;

// security
export const RESET_PASSWORD_STATE = `reset-password`;

export const SEARCH_FILTERS_PARENT_STATE = [
	PORTAL_MEDIA_ALL_STATE,
	PORTAL_MEDIA_BROWSE_STATE,
	PORTAL_MEDIA_UPLOADS_STATE,
	PORTAL_MEDIA_SEARCH_STATE,
	PORTAL_MEDIA_PENDING_VIDEOS_STATE,
	PORTAL_MEDIA_CATEGORY_DETAILS_STATE,
	PORTAL_MEDIA_EXPIRATIONS_STATE,
	PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE
];

