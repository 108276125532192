import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { ThumbnailCarouselType } from './BrandingSettings.Contract';

export const defaultLogo = {
	name: 'default-logo',
	url:  `${BootstrapContext.baseCdnUrl}/img/vbrick-product.png`,
	ecdnUrl: `${BootstrapContext.baseCdnUrl}/img/universal-ecdn.png`
};

export const defaultFont = {
	name: 'Open Sans',
	url:  `${BootstrapContext.baseCdnUrl}/font/OpenSans-Regular.woff`
};

export const defaultColors = {
	accentColor: '#108dda',
	accentFontColor: '#fff',
	featuredVideoBackgroundColor: '#141414',
	primaryColor:  '#141414',
	primaryFontColor: '#fff',
	channelFont: '#fff'
};

export const defaultThumbnailCarouselSettings = {
	carouselType: ThumbnailCarouselType.Left,
	overlayColor: '#000',
	overlayOpacity: 50
};

export const defaultHeaderColors = {
	background: '#1a1a1a',
	font: '#fff'
};

export const defaultBackgroundImageOpacity = 50;

export const defaultHelpUri = 'https://revdocs.vbrick.com/docs';
