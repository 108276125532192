import { IMessageDispatcher } from '@vbrick/vbrick-logging-client/src';

import { PlaybackUpdatedSubType } from 'rev-shared/videoPlayer/analytics/PlaybackUpdatedSubType';
import { RevLoggingClientBase } from 'rev-shared/logging/RevLoggingClientBase';
import { SpinnerType } from 'rev-shared/videoPlayer/analytics/SpinnerType';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { VideoHeartbeatErrorSubType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatErrorSubType';
import { VideoHeartbeatEventType } from 'rev-shared/videoPlayer/analytics/VideoHeartbeatEventType';
import { getBufferingSubType } from 'rev-shared/videoPlayer/analytics/BufferingSubType';
import { StreamType } from 'rev-shared/videoPlayer/StreamType';

import { VideoSourceType } from './VideoSourceType';
import { VideoType } from './VideoType';
import { SecondMs } from 'rev-shared/date/Time.Constant';

const DEFAULT_STREAM_DEVICE: string = 'Rev';
const DEFAULT_ZONE_NAME: string = 'Rev';
const DURATION_WIGGLE_ROOM_SECS: number = 2;
const VOD_MESSAGE_TYPE: string = 'VideoHeartbeat';

interface IHeartbeatData {
	bandwidth: number;
	bitrate: number;
	timeInVideo: number;
	viewContext: string;
	volume: number;
}

interface IPlaybackUpdatedData {
	deviceId: string;
	deviceName: string;
	durationSecs: number;
	failoverFromUrl?: string;
	sessionId: string;
	sourceType: VideoSourceType;
	streamAccessed: string;
	streamType: StreamType;
	subType: PlaybackUpdatedSubType;
	timeInVideo: number;
	title: string;
	videoFormat: string;
	videoPlayer: string; //HTML5 or Flash
	videoType: VideoType;
	viewContext: string;
	zoneId: string;
	zoneName: string;
	streamDeliveryType: string;
}

enum TimeUnit {
	MILLLISECONDS,
	SECONDS
}

export class VodPlayerLoggingClient extends RevLoggingClientBase {
	private deviceId: string;
	private disableLogHeartbeat: boolean;
	private durationSecs: number;
	private sessionId: string;
	private streamAccessed: string;
	private streamDevice: string;
	private videoId: string;
	private zoneName: string;
	private zoneId: string;
	private streamDeliveryType: string;
	private streamType: StreamType;

	constructor(
		UserContext: UserContextService,
		UserLocalIPService: UserLocalIPService,
		videoId: string,
		disableLogHeartbeat: boolean,
		messageDispatcher: IMessageDispatcher
	) {
		super(UserContext, UserLocalIPService, videoId, VOD_MESSAGE_TYPE, true, messageDispatcher);

		this.videoId = videoId;
		this.disableLogHeartbeat = disableLogHeartbeat;
	}

	public onBufferingStart(timeInVideoMs: number, isInitial: boolean): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.BUFFERING_START, {
			subType: getBufferingSubType(isInitial),
			timeInVideo: this.getSafeTimeInVideo(timeInVideoMs)
		});
	}

	public onBufferingStop(durationMs: number, isInitial: boolean, timeInVideoMs: number): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.BUFFERING_STOP, {
			duration: durationMs,
			timeInVideo: this.getSafeTimeInVideo(timeInVideoMs),
			subType: getBufferingSubType(isInitial)
		});
	}

	public onComplete(timeInVideoMs: number): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.COMPLETE, {
			timeInVideo: this.getSafeTimeInVideo(timeInVideoMs) ||
				this.durationSecs * SecondMs //duration
		});
	}

	public onError(data: { details?: any; isFatal: boolean; subType: VideoHeartbeatErrorSubType; timeInVideo?: number }): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.ERROR, {
			details: data.details,
			isFatal: data.isFatal,
			subType: data.subType,
			timeInVideo: this.getSafeTimeInVideo(data.timeInVideo),
		});
	}

	public onHeartbeat(data: IHeartbeatData): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.HEARTBEAT, {
			bandwidth: data.bandwidth,
			bitRate: data.bitrate,
			timeInVideo: this.getSafeTimeInVideo(data.timeInVideo),
			viewContext: data.viewContext,
			volume: data.volume
		});
	}

	public onPause(timeInVideoMs: number): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.PAUSE, {
			timeInVideo: this.getSafeTimeInVideo(timeInVideoMs)
		});
	}

	public onPlay(timeInVideoMs: number, isInitial: boolean, viewContext): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.PLAY, {
			isInitial,
			viewContext,
			timeInVideo: this.getSafeTimeInVideo(timeInVideoMs)
		});
	}

	public onPlaybackUpdated(data: IPlaybackUpdatedData): void {
		// capture current playback state
		this.streamDeliveryType = data.streamDeliveryType;
		this.deviceId = data.deviceId;
		this.durationSecs = data.durationSecs;
		this.sessionId = data.sessionId;
		this.streamAccessed = data.streamAccessed;
		this.streamDevice = data.deviceName;
		this.streamType = data.streamType;
		this.zoneId = data.zoneId;
		this.zoneName = data.zoneName;

		const playbackUpdatedData: any = {
			duration: data.durationSecs,
			subType: data.subType,
			sourceType: data.sourceType,
			timeInVideo: this.getSafeTimeInVideo(data.timeInVideo),
			title: data.title,
			videoFormat: data.videoFormat,
			videoPlayer: data.videoPlayer, //HTML5 or Flash
			videoType: data.videoType,
			viewContext: data.viewContext,
			viewSourceUrl: null
		};

		if (data.failoverFromUrl) {
			playbackUpdatedData.failOverFromUrl = data.failoverFromUrl;
		}

		this.logVideoHeartbeat(VideoHeartbeatEventType.PLAYBACK_UPDATED, playbackUpdatedData);
	}

	public onSeek(data: { seekFrom: number; seekTo: number }): void {
		const seekFrom = this.getSafeTimeInVideo(data.seekFrom, TimeUnit.SECONDS);

		this.logVideoHeartbeat(VideoHeartbeatEventType.SEEK, {
			seekFrom,
			seekTo: this.getSafeTimeInVideo(data.seekTo, TimeUnit.SECONDS),
			timeInVideo: seekFrom * SecondMs//backend asked for it specifically
		});
	}

	public onStop(): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.STOP, {});
	}

	public onBufferingSpinner(subType: SpinnerType, durationMs: number, timeInVideoMs: number): void {
		this.logVideoHeartbeat(VideoHeartbeatEventType.SPINNER, {
			duration: durationMs,
			timeInVideo: this.getSafeTimeInVideo(timeInVideoMs),
			subType
		});
	}

	protected logVideoHeartbeat(eventType: VideoHeartbeatEventType, message: any): void {
		if (this.disableLogHeartbeat) {
			return;
		}

		const user = this.UserContext.getUser();

		this.log({
			...message,
			streamDeliveryType: this.streamDeliveryType,
			deviceId: this.deviceId,
			email: user.email,
			eventType,
			firstName: user.firstName,
			fullName: user.fullName,
			lastName: user.lastName,
			sessionId: this.sessionId,
			streamAccessed: this.streamAccessed,
			streamDevice: this.streamDevice || DEFAULT_STREAM_DEVICE,
			streamType: this.streamType,
			username: user.username,
			userType: this.getUserType(),
			videoId: this.videoId,
			when: new Date(),
			zoneId: this.zoneId || DEFAULT_ZONE_NAME,
			zoneName: this.zoneName || DEFAULT_ZONE_NAME
		});
	}

	protected getSafeTimeInVideo(timeInVideo: number, unit: TimeUnit = TimeUnit.MILLLISECONDS): number {
		const timeInVideoSecs: number = unit === TimeUnit.MILLLISECONDS ?
			timeInVideo / SecondMs :
			timeInVideo;

		if (!this.durationSecs) {
			return timeInVideo;
		}

		const durationSecsWithWiggle: number = this.durationSecs + DURATION_WIGGLE_ROOM_SECS; // duration calculations may vary

		return timeInVideoSecs <= durationSecsWithWiggle ?
			timeInVideo : // return value in original units
			null;
	}
}
