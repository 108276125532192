<div *ngIf="status.ready" [ngClass]="styles.flexColumn">
	<div [ngClass]="styles.flexContainer">
		<div [ngClass]="styles.flexContainer" [tooltip]="(transcribe.isNotPermitted ? 'Media_Videos_Transcribe_NoRevIQ' :
				transcribe.isLocked ? 'Media_Videos_Transcribe_Locked' :
				'') | translate" placement="right">
			<vb-toggle-button [attr.name]="'transcribe' + videoTAiIndex" [ariaLabel]="'Media_Videos_Transcribe' | translate" [disabled]="transcribe.isLocked" [(ngModel)]="transcribe.isEnabled" (ngModelChange)="onTranscribeOptionChange($event);">
			</vb-toggle-button>
			<span [ngClass]="styles.toggleIcon" class="vb-icon vb-icon-center vb-icon-transcribe"></span>
			@if (transcribe.isNotPermitted) {
				<div [ngClass]="[styles.flexContainer, styles.toggleLabel]">
					{{ 'Media_Videos_Transcribe' | translate }}
				</div>
			}
			@else {
			<div [ngClass]="[styles.flexContainer, styles.toggleLabel]">
				{{ 'Media_Videos_Transcribe_Lang' | translate : { '0' : transcribe.languageName } }}
				<div *ngIf="!transcribe.isLocked && transcribe.isEnabled" dropdown [insideClick]="true" class="position-relative" #transcribeLangDropdown="bs-dropdown" vbDropdown>
					<button [attr.id]="'transcribeLangButton' + videoTAiIndex" [attr.aria-controls]="'transcribeLangMenu' + videoTAiIndex" type="button" class="btn btn-transparent" [ngClass]="styles.transcribeSelectBtn" dropdownToggle>
						<span>{{ 'Select' | translate }}</span>
					</button>
					<div *dropdownMenu [attr.id]="'transcribeLangMenu' + videoTAiIndex" [attr.aria-labelledby]="'transcribeLangButton' + videoTAiIndex" role="menu" class="dropdown-menu vb-scrollbar" [ngClass]="[
							styles.transcribeLangMenu,
							containerClass?.dropdownMenu ? containerClass.dropdownMenu : ''
						]">
						<button *ngFor="let language of availableLanguages" type="button" role="menuitem" class="btn btn-transparent dropdown-item" (click)="onTranscribeLanguageClick(language);transcribeLangDropdown.isOpen=false;">
							{{ language.name }}
						</button>
					</div>
				</div>
			</div>
			}
		</div>
	</div>
	<div *ngIf="!aiMetadata.isNotPermitted" [ngClass]="styles.flexColumn" class="margin-top-10 margin-bottom-10">
		<div [ngClass]="styles.flexContainer">
			<vb-toggle-button [attr.name]="'generateAll' + videoTAiIndex" [ariaLabel]="'GenerateAllMetadata' | translate" [disabled]="aiMetadata.isDisabled" [ngModel]="aiMetadata.all()" (ngModelChange)="updateAllMetadataFields($event)">
			</vb-toggle-button>
			<span [ngClass]="styles.toggleIcon" class="vb-icon vb-icon-sparkle"></span>
			<button type="button" class="btn-transparent padding-left-0 padding-right-0" display="flex" [ngClass]="styles.toggleLabel" (click)="expand ? expand = false : expand = true;">
				{{ 'GenerateAllMetadata' | translate }}
				<span class="glyphicons circle_info margin-left-10" tabindex="0" [tooltip]="'Video_Metadata_Generation_Disabled' | translate" [placement]="'right'">
				</span>
				<span [hidden]="aiMetadata.isDisabled" class="glyphicons chevron-right" [ngClass]="[styles.chevronIcon, expand ? 'chevron-down': '' ]">
				</span>
			</button>
		</div>
		<div [ngClass]="styles.flexColumn" [hidden]="!expand">
			<vb-toggle-button [attr.name]="'generateTitle' + videoTAiIndex" [ariaLabel]="'Title' | translate" [labelName]="'Title' | translate" [labelCssClass]="styles.toggleBtnLabel" [disabled]="aiMetadata.isDisabled" [ngModel]="aiMetadata.fields().title" (ngModelChange)="updateMetadataField('title', $event)">
			</vb-toggle-button>
			<vb-toggle-button [attr.name]="'generateDesc' + videoTAiIndex" [ariaLabel]="'Description' | translate" [labelName]="'Description' | translate" [labelCssClass]="styles.toggleBtnLabel" [disabled]="aiMetadata.isDisabled" [ngModel]="aiMetadata.fields().description" (ngModelChange)="updateMetadataField('description', $event)">
			</vb-toggle-button>
			<vb-toggle-button *ngIf="mediaFeatures?.enableTags" [attr.name]="'generateTags' + videoTAiIndex" [ariaLabel]="'Tags' | translate" [labelName]="'Tags' | translate" [labelCssClass]="styles.toggleBtnLabel" [disabled]="aiMetadata.isDisabled" [ngModel]="aiMetadata.fields().tags" (ngModelChange)="updateMetadataField('tags', $event)">
			</vb-toggle-button>
			<vb-toggle-button [attr.name]="'generateChapters' + videoTAiIndex" [ariaLabel]="'Media_Videos_Chapters' | translate" [labelName]="'Media_Videos_Chapters' | translate" [labelCssClass]="styles.toggleBtnLabel" [disabled]="aiMetadata.isDisabled" [ngModel]="aiMetadata.fields().chapters" (ngModelChange)="updateMetadataField('chapters', $event)">
			</vb-toggle-button>
		</div>
	</div>
</div>
