import { ResolvableLiteral, Transition } from '@uirouter/angular';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { MEDIA_EAGER_STATES } from 'rev-portal/media/MediaEagerStates';
import { TEAM_EAGER_STATES } from 'rev-portal/media/teams/TeamEagerStates';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { PORTAL_ROOT_STATE, PORTAL_DASHBOARD_STATE, PORTAL_VIDEO_PLAYBACK_STATE, PORTAL_VIDEO_PLAYLIST_PLAYBACK_STATE, PORTAL_VIDEO_SETTINGS_STATE, PORTAL_USER_PROFILE, PORTAL_VIDEO_EDIT_STATE } from 'rev-shared/bootstrap/AppStateNames';
import { Portal404Component } from 'rev-shared/security/components/Portal404.Component';
import { Unauthorized401Component } from 'rev-shared/security/components/Unauthorized401.Component';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

const params401 = {
	reason: {}
};

export function getPortalBaseStates(componentName?: any, isAbstract?: boolean, resolves?: ResolvableLiteral[], ignoreStandalone401?: boolean): IVbNg2StateDeclaration[] {
	return [
		{
			name: PORTAL_ROOT_STATE,
			abstract: isAbstract,
			component: componentName,
			allowGuestAccess: true,
			allowPublicVideoAccess: true,
			allowRegisteredGuestAccess: true,
			resolve: [
				{
					token: 'accountId',
					deps: [
						UserContextService
					],
					resolveFn: (UserContext: UserContextService) => UserContext.getAccount().id
				},
				{
					token: 'initTheme',
					deps: [
						ThemeService
					],
					resolveFn: (ThemeService: ThemeService) => {
						ThemeService.initialize();
					}
				},
				{
					token: 'isUniversalEcdn',
					deps: [
						UserContextService
					],
					resolveFn: (UserContext: UserContextService) => UserContext.getAccount().isUniversalEcdn
				},
				...(resolves || [])
			]
		},
		{
			name: `${PORTAL_DASHBOARD_STATE}.**`,
			url: '/',
			loadChildren: () =>
				import(/* webpackChunkName: 'portalDasboard' */ './dashboard/Dashboard.Module')
					.then(mod => mod.DashboardModule)
		},
		...MEDIA_EAGER_STATES,
		...TEAM_EAGER_STATES,
		{
			name: `${PORTAL_VIDEO_PLAYBACK_STATE}.**`,
			url: '/videos/:videoId?startAt&autoplay&query',
			loadChildren: () =>
				import(/* webpackChunkName: 'portalVideoPlayback' */ './media/videos/videoPlayback/VideoPlaybackAngular.Module')
					.then(mod => mod.VideoPlaybackAngularModule)
		},
		{
			name: `${PORTAL_VIDEO_PLAYLIST_PLAYBACK_STATE}.**`,
			url: '/playlist/:playlistId/videos/?videoId',
			loadChildren: () =>
				import(/* webpackChunkName: 'portalVideoPlayback' */ './media/videos/videoPlayback/VideoPlaybackAngular.Module')
					.then(mod => mod.VideoPlaybackAngularModule)
		},
		{
			name: `${PORTAL_VIDEO_SETTINGS_STATE}.**`,
			url: '/video-settings/:videoId',
			loadChildren: () =>
				import(/* webpackChunkName: 'portalVideoSettings' */ './media/videos/VideoSettings/VideoSettings.Module')
					.then(mod => mod.VideoSettingsModule)
		},
		{
			name: `${PORTAL_USER_PROFILE}.**`,
			url: '/user-profile',
			loadChildren: () =>
				import(/* webpackChunkName: 'portalUserProfile' */ './userProfile/UserProfile.Module')
					.then(mod => mod.UserProfileModule)
		},
		{
			name: `${PORTAL_VIDEO_EDIT_STATE}.**`,
			url: '/videos/:videoId/edit',
			loadChildren() {
				return import(/* webpackChunkName: 'portalVideoEdit' */ './media/videos/editVideo/EditVideo.Module')
					.then(m => m.EditVideoModule);
			}
		},
		!ignoreStandalone401 && {
			name: '401', //standalone 401
			url: '/401',
			component: Unauthorized401Component,
			allowAllAccess: true,
			params: params401,
			resolve: [
				{
					token: 'reason',
					deps: [
						Transition
					],
					resolveFn: (transition: Transition) => transition.params().reason
				}
			]
		},
		{
			name: 'portal.401', //401 framed within the portal
			url: '/401-unauthorized',
			component: Unauthorized401Component,
			allowAllAccess: true,
			params: params401,
			resolve: [
				{
					token: 'reason',
					deps: [
						Transition
					],
					resolveFn: (transition: Transition) => transition.params().reason
				}
			]
		},
		{
			name: 'portal.404', //401 framed within the portal
			url: '/404',
			component: Portal404Component,
			allowAllAccess: true,
		}
	].filter(Boolean);
}
