
export const MediumDisplayWidth = 992;
export const SmallDisplayWidth = 768;
export const ExtraSmallMobilePortraitWidth = 480;

export const MinWidthMediumMediaQuery = `(min-width: ${MediumDisplayWidth}px)`;
export const MinWidthSmallMediaQuery = `(min-width: ${SmallDisplayWidth}px)`;

export const MaxWidthSmallMediaQuery = `(max-width: ${MediumDisplayWidth - 1}px)`;
export const MaxWidthExtraSmallMediaQuery = `(max-width: ${SmallDisplayWidth - 1}px)`;
export const MaxWidthMobilePortraitMediaQuery = `(max-width: ${ExtraSmallMobilePortraitWidth}px)`;
