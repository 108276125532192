import {
	Directive,
	Input,
	SimpleChanges,
	forwardRef
} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

/***
 * Directive to validate HLS playback URL
 * This directive can be used in url text input field and when [vbHlsPlaybackUrl]="true" is set, it will validate the control value.
 */

@Directive({
	selector: '[vbHlsPlaybackUrl]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbHlsPlaybackUrlDirective),
		multi: true
	}]
})
export class VbHlsPlaybackUrlDirective {
	@Input() public vbHlsPlaybackUrl: boolean;
	private control: AbstractControl;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.vbHlsPlaybackUrl) {
			this.control?.updateValueAndValidity();
			if (!this.control?.touched) {
				this.control?.markAsTouched();
			}
		}
	}

	public validate(control: AbstractControl): ValidationErrors {
		if(!this.control && !(control as any).dummy) {
			this.control = control;
		}

		return (!this.vbHlsPlaybackUrl || ValidationRules.checkHlsPlaybackUrl(this.control?.value)) ?
			null :
			{ invalidHlsUrl: true };
	}
}
