import { NgModule } from '@angular/core';

import { DATE_PARSER_FACTORY_SERVICE_TOKEN, getDateParserService } from './DateParser.Provider';
import { TimezoneService } from './Timezone.Service';
import { VbDateTimeMediumPipe, VbDateMediumPipe, VbTimeShortPipe } from './VbDateFormats.Pipe';
import { VbExcludeSecondsFromTimespanPipe } from './VbExcludeSecondsFromTimespan.Pipe';
import { VbFormatDatePipe } from './VbFormatDateAngular.Pipe';
import { VbFormatTimespanLargeHoursPipe } from './VbFormatTimespanLargeHours.Pipe';
import { VbTimeInputDirective } from './VbTimeInputAngular.Directive';
import { VbTimespanPipe } from './VbTimespan.Pipe';
import { VbTimespanToHoursPipe } from './VbTimespanToHoursAngular.pipe';
import { VbTimespanToSecondsPipe } from './VbTimespanToSeconds.Pipe';
import { VbFormatDateRange } from './VbFormatDateRange.Pipe';

const pipes = [
	VbDateMediumPipe,
	VbDateTimeMediumPipe,
	VbExcludeSecondsFromTimespanPipe,
	VbFormatDatePipe,
	VbFormatDateRange,
	VbFormatTimespanLargeHoursPipe,
	VbTimeShortPipe,
	VbTimespanPipe,
	VbTimespanToHoursPipe,
	VbTimespanToSecondsPipe
];
const components = [
	VbTimeInputDirective
];

@NgModule({
	declarations: [...components, ...pipes],
	exports: [...components, ...pipes],
	providers: [
		TimezoneService,
		{ provide: DATE_PARSER_FACTORY_SERVICE_TOKEN, useFactory: getDateParserService },
	]
})
export class DateAngularModule {}
