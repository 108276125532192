import { IVbrickPlayerConfig } from 'vbrick-player-src/IVbrickPlayerConfig';

import { IRevPlayerSettings } from './IRevPlayerSettings';
import { isSafari } from 'rev-shared/util/UserAgentUtil';

export const VbrickPlayerDefaultConfig: IVbrickPlayerConfig = {
	hls: {
		isBitrateObserved: true,
		isFragLoadWorker: false,
		isFragLoadWorkerForDual: !isSafari(), //AV-33766 - Safari 18.3 has Worker issue. postMessage to Worker not working. Disabling for Safari.
		isMbrPlaybackDisabledForDual: false,
		isParseTimeIgnored: true
	}
};

export function mergeWithDefaultPlayerConfig(overrides: Partial<IRevPlayerSettings>): IVbrickPlayerConfig {
	return {
		hls: {
			...VbrickPlayerDefaultConfig.hls,
			...overrides // flat object, but presently consists exclusively of hls settings
		}
	};
}
