import { Component, Input, OnInit, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';

import styles from './vb-toggle-button.module.less';

let nextId = 1;

@Component({
	selector: 'vb-toggle-button',
	templateUrl: './VbToggleButton.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbToggleButtonComponent),
		multi: true
	}],
	host: {
		'[class]': 'styles.root'
	}
})
export class VbToggleButtonComponent extends BaseControlValueAccessor<boolean> implements OnInit, OnChanges {
	@Input() public toggleId: string = `vb-toggle-btn-${nextId++}`;
	@Input() public disabled: boolean;
	@Input() public ariaLabel: string;
	@Input() public labelName: string;
	@Input() public labelCssClass: boolean;

	public readonly styles = styles;

	public ngOnInit(): void {
		this.setDisabledState(this.disabled);
	}

	public ngOnChanges(changes): void {
		if (changes.disabled) {
			this.setDisabledState(this.disabled);
		}
	}

	public onChange(): void {
		this.updateModelValue(this.value);
	}

}
