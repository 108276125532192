import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { CategoryParentPathPipe } from 'rev-shared/media/CategoryParentPath.Pipe';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';

import { VodPlayerLoggingService } from './VodPlayerLogging.Service';

@NgModule({
	declarations: [
		CategoryParentPathPipe
	],
	exports: [
		CategoryParentPathPipe
	],
	imports: [
		CommonModule,
		DateAngularModule,
		SecurityAngularModule,
		TranslateModule,
		UtilAngularModule
	],
	providers: [
		VodPlayerLoggingService
	]
})
export class VbrickSharedMediaModule {}
