import { loadNgModule } from '@uirouter/angular';
import {
	PORTAL_MEDIA_STATE,
	PORTAL_MEDIA_ALL_STATE,
	PORTAL_MEDIA_UPLOADS_STATE,
	PORTAL_MEDIA_SEARCH_STATE,
	PORTAL_MEDIA_BROWSE_STATE,
	PORTAL_MEDIA_CATEGORY_DETAILS_STATE,
	PORTAL_MEDIA_PENDING_VIDEOS_STATE,
	PORTAL_MEDIA_EXPIRATIONS_STATE,
	PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE,
	PLAYLIST_ROOT_STATE, MY_PLAYLIST_STATE,
	PLAYLIST_DETAIL_STATE,
	FEATURED_PLAYLIST_DETAIL_STATE,
	PORTAL_MEDIA_EDIT_STATE,
	SEARCH_FILTERS_PARENT_STATE
} from 'rev-shared/bootstrap/AppStateNames';
import {
	FEATURED_PLAYLIST_DETAIL_STATE_URL,
	MY_PLAYLIST_STATE_URL,
	PLAYLIST_DETAIL_STATE_URL,
	PLAYLIST_ROOT_STATE_URL
} from './playlists/Playlists.Constant';

export const MEDIA_EAGER_STATES = [
	{
		name: `${PORTAL_MEDIA_STATE}`,
		url: '/media?team&sortField&viewMode&desc&:filters',
		lazyLoad: (transition, stateObject) => {
			const { stateRegistry } = transition.router;
			const callback = loadNgModule(() => import('./Media.Module')
				.then(m => {
					// Deregister the eager placeholders before loading the lazy loaded NgModule.
					stateRegistry.deregister(PORTAL_MEDIA_STATE);
					return m.MediaModule;
				}));
			return callback(transition, stateObject);
		}
	},
	{
		name: PORTAL_MEDIA_ALL_STATE,
		url: '/all'
	},
	{
		name: PORTAL_MEDIA_UPLOADS_STATE,
		url: '/uploads'
	},
	{
		name: PORTAL_MEDIA_SEARCH_STATE,
		url: '/search?{q:any}'
	},
	{
		name: PORTAL_MEDIA_BROWSE_STATE,
		url: '/browse'
	},
	{
		name: PORTAL_MEDIA_CATEGORY_DETAILS_STATE,
		url: '/videos/category/:categoryId'
	},
	{
		name: PORTAL_MEDIA_PENDING_VIDEOS_STATE,
		url: '/pending'
	},
	{
		name: PORTAL_MEDIA_EXPIRATIONS_STATE,
		url: '/expirations'
	},
	{
		name: PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE,
		url: '/subscriptions'
	},
	{
		name: PLAYLIST_ROOT_STATE,
		url: PLAYLIST_ROOT_STATE_URL
	},
	{
		name: MY_PLAYLIST_STATE,
		url: MY_PLAYLIST_STATE_URL
	},
	{
		name: PLAYLIST_DETAIL_STATE,
		url: PLAYLIST_DETAIL_STATE_URL
	},
	{
		name: FEATURED_PLAYLIST_DETAIL_STATE,
		url: FEATURED_PLAYLIST_DETAIL_STATE_URL
	},
	...SEARCH_FILTERS_PARENT_STATE.map((state: string) => ({
		name: `${state}.filters-sidebar`,
		url: '/filters?filters',
	})),
	{
		name: PORTAL_MEDIA_EDIT_STATE,
		url: '/edit?{q:any}'
	},
];
