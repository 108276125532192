import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { formatShortTime, formatMediumDate } from 'rev-shared/date/DateFormatters';

/**
 * Pipe to format a date range. If the start and end date are on the same day, it will only show the time for endDate else
 * it will show the date and time for both.
 */

@Pipe({
	name: 'vbFormatDateRange'
})
export class VbFormatDateRange implements PipeTransform {
	public transform(startDateTime: Date | string, endDateTime: Date | string, dateFormat: string = 'medium'): string {
		const start = new Date(startDateTime);
		const end = new Date(endDateTime);

		const isSameDay: boolean = DateUtil.isSameDay(end, start);
		return `${this.formatDate(start, true)} - ${this.formatDate(end, !isSameDay)}`;
	}

	private formatDate(date: Date, addDay?: boolean): string {
		const time = formatShortTime(date);
		return addDay ? `${formatMediumDate(date)} ${time}` : time;
	}

}
