import { loadNgModule } from '@uirouter/angular';
import { PORTAL_TEAM_STATE, PORTAL_TEAM_ALL_STATE, PORTAL_TEAM_EDIT_STATE } from 'rev-shared/bootstrap/AppStateNames';

export const TEAM_EAGER_STATES = [
	{
		name: PORTAL_TEAM_STATE,
		url: '/teams',
		lazyLoad: (transition, stateObject) => {
			const { stateRegistry } = transition.router;
			const callback = loadNgModule(() => import('./PortalTeams.Module')
				.then(m => {
					// Deregister the eager placeholders before loading the lazy loaded NgModule.
					stateRegistry.deregister(PORTAL_TEAM_STATE);
					return m.PortalTeamsModule;
				}));
			return callback(transition, stateObject);
		},
	},
	{
		name: PORTAL_TEAM_ALL_STATE,
		url: '/all'
	},
	{
		name: PORTAL_TEAM_EDIT_STATE,
		url: '/edit-team/:teamId',
		params: {
			teamId: null
		}
	}
];
