import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy, Signal } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TransitionService } from '@uirouter/angular';

import { UploadService } from 'rev-portal/media/import/Upload.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { EncodingTypes } from 'rev-shared/constants/EncodingTypes';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';
import { noop } from 'rev-shared/util';
import { PORTAL_MEDIA_UPLOADS_STATE, PORTAL_VIDEO_SETTINGS_STATE } from 'rev-shared/bootstrap/AppStateNames';

import { BaseUploadComponent } from './BaseUpload.Component';
import { DirectUrlType, UploadUrlType } from './UploadConstant';

import './upload-menu.less';
import styles from './UploadMenu.module.less';

interface IUploadMenuStatus {
	active: boolean;
	error: boolean;
	loading: boolean;
	processing: boolean;
}

@Component({
	selector: 'upload-menu',
	templateUrl: './UploadMenu.Component.html',
})
export class UploadMenuComponent extends BaseUploadComponent implements OnInit, OnDestroy {
	@Input() public teamId: string;
	@Input() public canTeamRecord: boolean;
	@Output() private onCloseUploadMenu: EventEmitter<any> = new EventEmitter();
	@ViewChild('form') public form: NgForm;

	private unsubscribeTransitionHook: () => void;
	private unsubscribePushHandlers: IUnsubscribe;

	public readonly DirectUrlType = DirectUrlType;
	public readonly UploadUrlType = UploadUrlType;
	public readonly EncodingTypes: string[] = EncodingTypes;
	public readonly PORTAL_MEDIA_UPLOADS_STATE = PORTAL_MEDIA_UPLOADS_STATE;
	public readonly PORTAL_VIDEO_SETTINGS_STATE = PORTAL_VIDEO_SETTINGS_STATE;

	public status: IUploadMenuStatus;
	public presentationProfiles: string[];
	public videoLink: any;
	public videoLinks: any[];

	public isZoomManualImportEnabled: boolean;
	public isRecordingAllowed: boolean;
	public isWebexManualImportEnabled: boolean;
	public videoImports: Signal<any[]>;


	constructor(
		private DialogService: DialogService,
		private UploadService: UploadService,
		private $transitions: TransitionService,
		private SecurityContext: SecurityContextService,
	) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.videoLinks = [];
		this.videoLink = {};
		this.videoImports = this.UploadService.getImportStatuses(this.teamId);

		this.initialize();
		this.unsubscribeTransitionHook = this.$transitions.onStart({}, () => this.closeUploadMenu()) as () => void;
		this.unsubscribePushHandlers = this.subscribePushHandlers();
	}

	public ngOnDestroy(): void {
		this.unsubscribeTransitionHook?.();
		this.unsubscribePushHandlers?.();
		super.ngOnDestroy();
	}

	public closeUploadMenu(): void {
		this.onCloseUploadMenu.emit();
	}

	private subscribePushHandlers(): IUnsubscribe {
		return this.PushBus.subscribe(this.accountId, 'Media.Videos', {
			// TODO: move this when "videoLinks" upload flow is decoupled out
			VideoDeleted: data => {
				const i = this.videoLinks.findIndex(upload => upload.id === data.videoId);
				if (i >= 0) {
					this.videoLinks.splice(i, 1);
				}
			}
		});
	}

	private initialize(): void {
		this.resetStatus();
		this.resetVideoLink();
		this.features = {
			enableAddUrls: false,
			enableSpark: false,
			vciSettings: {
				isEnabled: false,
				isMsTeamsEnabled: false,
			},
			zoomSettings: {
				isEnabled: false,
				sipAddressSuffix: ''
			}
		};
		this.status.loading = true;

		this.loadServiceDetails()
			.then(() => {
				this.resetStatus();
				this.resetVideoLink();

				const isRecordingAuthorized = this.teamId ? this.canTeamRecord : this.SecurityContext.checkAuthorization('media.record');

				this.isZoomManualImportEnabled = this.features.zoomSettings.importRecordingEnabled && !!this.userEmail;
				this.isRecordingAllowed = this.features?.recordingPolicy !== RecordingPolicy.DISABLE && isRecordingAuthorized;
				this.isWebexManualImportEnabled = this.features?.webexSitesSettings?.some(site => site.isEnabled && site.enableManualImport);
				this.status.active = true;
				this.Zone.run(noop);
			});
	}

	private resetStatus(): void {
		this.status = {
			active: false,
			error: false,
			loading: false,
			processing: false
		};
	}

	public importWebEx(): void {
		this.DialogService
			.getDialog('importWebexDialog')
			.open({
				initialState: {
					webexSitesSettings: (this.features.webexSitesSettings || []).filter(site => site.isEnabled && site.enableManualImport && site.webexCiLinked),
					teamId: this.teamId
				},
				class: `back-drop modal-lg ${styles.importWebexDialogModal}`
			});
	}

	public importZoom(): void {
		this.DialogService
			.getDialog('importZoomDialog')
			.open({
				initialState: {
					webexSitesSettings: (this.features.webexSitesSettings || []).filter(site => site.isEnabled && site.enableManualImport),
					teamId: this.teamId
				},
				class: 'back-drop modal-lg'
			});
	}

	public dismissAllImports(): void {
		this.UploadService.dismissAllImportStatuses(this.teamId);
	}

	private addVideoByDirectUrl({ directUrl }) {
		const video = {
			accountId: this.accountId,
			encodingType: directUrl.encodingType,
			type: directUrl.type,
			url: directUrl.url
		};

		this.UploadService.createVideoLink(video, this.teamId)
			.then(videoId => {
				this.videoCreationSuccess({
					id: videoId,
					title: video.url
				});
			})
			.catch(error => {
				this.videoCreationFailure({
					title: video.url
				});
			});
	}

	public loadPresentationProfiles() {
		return this.UploadService.fetchPresentationProfiles(this.accountId, this.teamId)
			.then(data => this.presentationProfiles = data.presentationProfiles);
	}

	private addVideoByPresentationProfile({ presentationProfile }) {
		this.UploadService.createVideoByPresentationProfile(presentationProfile, this.teamId)
			.then(videoId => this.videoCreationSuccess({
				id: videoId,
				title: presentationProfile.name
			}))
			.catch(() => this.videoCreationFailure({
				title: presentationProfile.name
			}));
	}

	public addVideoLink(): void {
		this.resetStatus();
		this.status.processing = true;
		const { linkType } = this.videoLink;

		switch (linkType) {
			case UploadUrlType.DIRECT_URL:
				this.addVideoByDirectUrl(this.videoLink);
				break;
			case UploadUrlType.PRESENTATION_PROFILE:
				this.addVideoByPresentationProfile(this.videoLink);
				break;
		}
		this.form.form.reset();
	}

	public dismissAllVideoLinks(): void {
		this.videoLinks = [];
	}

	private resetVideoLink(): void {
		this.videoLink = {
			directUrl: {
				url: undefined,
				type: DirectUrlType.LIVE,
				encodingType: EncodingTypes[0]
			},
			linkType: UploadUrlType.DIRECT_URL,
			presentationProfile: undefined
		};
	}

	private videoCreationSuccess(video: any): void {
		this.resetStatus();
		this.status.active = true;

		this.videoLinks.push({
			id: video.id,
			status: { complete: true },
			title: video.title,
			uploadDate: new Date()
		});

		this.resetVideoLink();

		this.form.form.markAsPristine();
	}

	private videoCreationFailure(video: any): void {
		this.resetStatus();
		this.status.active = true;

		this.videoLinks.push({
			title: video.title,
			status: { error: true }
		});
	}
}
