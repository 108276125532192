<div dropdown container="body" [ngClass]="styles.menuWrapper" vbDropdown>
	@if (isMenuPopulated) {
		<button type="button" class="dropdown-toggle" [ngClass]="customIconClass ? 'btn-transparent' : styles.actionButton" vbBtnWhite dropdownToggle>
			@if (actionBtnInfo?.label) {
				{{ actionBtnInfo.label }}
			} @else {
				@if (customIconClass) {
					<span [ngClass]="customIconClass"></span>
				} @else {
					<span class="more-icon" [ngClass]="styles.moreIcon">
						<span class="circle"></span>
						<span class="circle"></span>
						<span class="circle"></span>
					</span>
				}
			}
		</button>
	}

	<div *dropdownMenu role="menu" class="dropdown-menu" [ngClass]="[
			styles.menuItemWrapper,
			themed ? styles.themedWrapper : '',
			menuWrapperCssClass || ''
		]">
		@for (menuItem of menuItemsInfo; track $index) {
			@if (menuItem.isVisible?.(data) || !menuItem.isVisible) {
				<button role="menuitem" class="btn btn-transparent dropdown-item" [ngClass]="styles.menuItem" [title]="menuItem.label || (menuItem.labelKey | translate)" (click)="onClick(menuItem)">
					@if (menuItem.iconClass) {
						<span class="margin-right-5" [ngClass]="menuItem.iconClass"></span>
					}
					{{ menuItem.label || (menuItem.labelKey | translate) }}
				</button>
			}
		}
	</div>
</div>
