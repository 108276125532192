import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { AgEvent, GridApi, ICellRendererParams, IRowNode } from '@ag-grid-community/core';

import styles from './ActionMenuButtonCellRenderer.Component.module.less';

interface IActionMenuItem {
	action: string;
	label?: string;
	labelKey?: string;
	isVisible?: (data: any) => boolean;
	iconClass?: string;
}

interface IActionButton {
	label: string;
}

interface IActionMenubuttonCellRendererParams extends ICellRendererParams, IActionMenuButtonCellRendererDef {}

export interface IActionMenuButtonCellRendererDef {
	actionButton?: IActionButton;
	getMenuItems: (rowNode?: any) => IActionMenuItem[];
	menuWrapperCssClass?: string;
	customIconClass?: string;
	rootClass?: string;
	themed?: boolean;
}

export interface ActionMenuClickEvent extends AgEvent {
	action: string;
	rowNode: IRowNode;
}

export const ACTION_MENU_CLICK: string = 'actionMenuClick';

@Component({
	selector: 'action-menu-button-cell-renderer',
	templateUrl: './ActionMenuButtonCellRenderer.Component.html',
	host: {
		'[class]': "rootClass || ''"
	}
})
export class ActionMenuButtonCellRendererComponent implements ICellRendererAngularComp {
	public actionBtnInfo: IActionButton;
	public menuItemsInfo: IActionMenuItem[];
	public menuWrapperCssClass: string;
	public customIconClass: string;
	public rootClass: string;
	public data: any;
	public themed: boolean;

	public readonly styles = styles;

	private gridApi: GridApi;
	private rowNode: IRowNode;

	public agInit(params: IActionMenubuttonCellRendererParams): void {
		this.updateCell(params);
	}

	public onClick(menuItem: IActionMenuItem): void {
		this.gridApi.dispatchEvent({
			action: menuItem.action,
			rowNode: this.rowNode,
			type: ACTION_MENU_CLICK
		} as ActionMenuClickEvent);
	}

	public refresh(params: IActionMenubuttonCellRendererParams): boolean {
		this.updateCell(params);

		return true; // nothing to do on refresh, so signal success
	}

	public get isMenuPopulated(): boolean {
		return this.menuItemsInfo?.some(menuItem => menuItem.isVisible?.(this.data) || !menuItem.isVisible);
	}

	private updateCell(params: IActionMenubuttonCellRendererParams): void {
		this.actionBtnInfo = params.actionButton;
		this.gridApi = params.api;
		this.data = params.data;
		this.menuItemsInfo = params.getMenuItems(params.data);
		this.rowNode = params.node;
		this.menuWrapperCssClass = params.menuWrapperCssClass;
		this.customIconClass = params.customIconClass;
		this.rootClass = params.rootClass;
		this.themed = params.themed;
	}
}
