import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgModel, Validators } from '@angular/forms';

import { Transition, StateService } from '@uirouter/angular';

import { PasswordValidationService } from 'rev-shared/security/PasswordValidation.Service';
import { UserAuthenticationService } from 'rev-shared/security/UserAuthentication.Service';
import { PasswordRulesChecker, IPasswordValidationResult } from 'rev-shared/security/PasswordRulesChecker';

import authLayoutStyles from 'rev-shared/ui/authLayout/VbAuthLayout.Module.less';

export const PasswordResetResolve = {
	token($transition$: Transition): string {
		'ngInject';

		return $transition$.params().token;
	}
};

interface IPasswordReset {
	accountId: string;
	confirmPassword: string;
	password: string;
	securityAnswer: string;
	securityQuestion: string;
	userId: string;
}

@Component({
	selector: 'password-reset',
	templateUrl: './PasswordReset.Component.html'
})
export class PasswordResetComponent implements OnInit, AfterViewInit {
	@Input() public token: string;
	@Input() public isPasswordExpired: boolean;

	@ViewChild('confirmPasswordControl', { static: true }) private ctrlConfirmPassword: NgModel;
	@ViewChild('passwordControl', { static: true }) private ctrlPassword: NgModel;

	public readonly authLayoutStyles = authLayoutStyles;

	public ctrlPasswordResult: IPasswordValidationResult = {} as any;
	public passwordReset: IPasswordReset = {} as any;
	public passwordRules: any = {};
	private passwordRulesChecker: PasswordRulesChecker;
	public status: any;
	private userId: string;

	constructor(
		private $state: StateService,
		private PasswordValidationService: PasswordValidationService,
		private UserAuthenticationService: UserAuthenticationService
	) {}

	public ngOnInit(): void {
		this.loadData();
	}

	public ngAfterViewInit(): void {
		this.ctrlConfirmPassword.control.setValidators(Validators.compose([
			this.ctrlConfirmPassword.control.validator,
			() => this.validateCtrlConfirmPassword()
		]));

		this.ctrlPassword.control.setValidators(Validators.compose([
			this.ctrlPassword.control.validator,
			() => this.validateCtrlPassword()
		]));
	}

	public submit(): void {
		this.resetStatus();
		this.status.loading = true;

		const data = {
			token: this.token,
			userId: this.passwordReset.userId,
			securityAnswer: this.passwordReset.securityAnswer,
			accountId: this.passwordReset.accountId,
			password: this.passwordReset.password
		};

		this.UserAuthenticationService.resetPassword(data)
			.then(() =>	this.$state.go('login'), (result: any) => {
				this.resetStatus();

				if (result === 'PasswordResetFailed') {
					this.status.active = true;
					this.status.passwordResetFailed = true;
				} else if (result.hasIssue('AwaitingSecurityQuestionReset')) {
					this.status.awaitingSecurityQuestionReset = true;
				} else {
					this.status.error = true;
				}
			});
	}

	private getPasswordReset(token: string): Promise<any> {
		return this.UserAuthenticationService.getUserPasswordReset(token)
			.then(passwordReset => Object.assign(this, {
				passwordReset,
				userId: passwordReset.userId
			}));
	}

	private getPasswordRulesChecker(): Promise<void> {
		return Promise.resolve( // TODO: remove once service is migrated
			this.PasswordValidationService.getPasswordRulesChecker(this.passwordReset.accountId)
				.then((result: PasswordRulesChecker) => {
					this.passwordRulesChecker = result;
					this.passwordRules = this.passwordRulesChecker.rules;
				})
		);
	}

	private loadData(): void {
		this.resetStatus();
		this.status.loading = true;

		this.getPasswordReset(this.token)
			.then(() => this.getPasswordRulesChecker())
			.then(() => {
				this.resetStatus();

				this.status.active = true;
			}, (err: any) => {
				this.resetStatus();

				if (err.status === 404) {
					this.status.invalidToken = true;
				} else {
					this.status.error = true;
				}
			});
	}

	private resetStatus(): void {
		this.status = {};
	}

	private validateCtrlConfirmPassword(): { [key: string]: boolean } {
		if (this.ctrlPassword.value !== this.ctrlConfirmPassword.value) {
			return { noMatch: true };
		}

		return null;
	}

	private validateCtrlPassword(): { [key: string]: boolean } {
		// trigger revalidation of the confirmation to check for a match
		this.ctrlConfirmPassword.control.updateValueAndValidity();

		if (!this.passwordRulesChecker) {
			return null;
		}

		const result: IPasswordValidationResult = this.passwordRulesChecker.validatePassword(this.ctrlPassword.value);

		this.ctrlPasswordResult = result;

		if (!result.valid) {
			return { rules: true };
		}

		return null;
	}
}
