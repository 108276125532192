import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';

import styles from './VbUiThumbnailSheetPreview.Component.module.less';

export interface IThumbnailSheetConfig {
	horizontalTiles: number,
	verticalTiles: number,
	spf: number,
	totalThumbnails: number,
	sheetWidth: number,
	sheetHeight: number,
	thumbnailSheetsUri: string,
	numSheets: number
}

@Component({
	selector: 'vb-ui-thumbnail-sheet-preview',
	templateUrl: './VbUiThumbnailSheetPreview.Component.html',
	host: {
		'[class]': 'styles.root',
		'[style.width.px]': 'width',
		'[style.height.px]': 'height'
	}
})
export class VbUiThumbnailSheetPreviewComponent implements AfterViewInit, OnChanges, OnDestroy {
	public readonly styles = styles;

	@Input() public thumbnailUri: string;
	@Input() public thumbnailSheetCfg: IThumbnailSheetConfig;
	@Input() public width: number;
	@Input() public height: number;
	@Input() public msPerFrame: number;
	@Input() public cssClass: string;
	@Input() public altTxt: string;

	public thumbnailPageOne: string;
	public isSlideshowActive: boolean = false;

	public widthStyle: number;
	public heightStyle: number;
	public bgImageStyle: string;
	public bgSizeStyle: string;

	private slideshowTimer: number;
	private thumbnailIndex: number = 0;

	@ViewChild('bgThumbnail')
	public thumbnailElementRef: ElementRef<HTMLElement>;

	public ngOnChanges(): void {
		this.updateStyle();
	}

	public ngAfterViewInit(): void {
		this.updateStyle();
	}

	public ngOnDestroy(): void {
		this.stopSlideshow();
	}

	@HostListener('mouseenter')
	public startSlideshow(): void {
		if (!this.thumbnailSheetCfg || this.isSlideshowActive) {
			return;
		}

		this.isSlideshowActive = true;

		const scheduleNextFrame = () => {
			this.slideshowTimer = window.setTimeout(() => {
				this.thumbnailIndex = (this.thumbnailIndex + 1) % this.thumbnailSheetCfg.totalThumbnails;
				this.setBackgroundPos();
				scheduleNextFrame();
			}, this.msPerFrame || 100);
		};

		this.loadThumbnails()
			.then(() => {
				if(this.isSlideshowActive) {
					scheduleNextFrame();
					this.updateStyle();
					this.setBackgroundPos();
				}
			})
			.catch(() => console.log('Failed to load thumbnail sheet'));
	}

	@HostListener('mouseleave')
	public stopSlideshow(): void {
		if (!this.isSlideshowActive) {
			return;
		}
		clearTimeout(this.slideshowTimer);
		this.slideshowTimer = null;
		this.isSlideshowActive = false;
		this.thumbnailIndex = 0;
		this.updateStyle();
		this.setBackgroundPos();
	}

	private setBackgroundPos(): void {
		const div = this.thumbnailElementRef.nativeElement;
		if(this.isSlideshowActive) {
			const x = (this.thumbnailIndex % this.thumbnailSheetCfg.horizontalTiles) * this.widthStyle;
			const y = Math.floor(this.thumbnailIndex / this.thumbnailSheetCfg.horizontalTiles) * this.heightStyle;
			div.style.backgroundPositionX = -x + 'px';
			div.style.backgroundPositionY = -y + 'px';
		}
		else {
			div.style.backgroundPositionX = '';
			div.style.backgroundPositionY = '';
		}
	}

	private updateStyle(): void {
		if(this.isSlideshowActive) {
			const { sheetWidth, sheetHeight, horizontalTiles, verticalTiles } = this.thumbnailSheetCfg;
			const thumbAspect = sheetWidth / sheetHeight * verticalTiles / horizontalTiles;
			[this.widthStyle, this.heightStyle] = scaleToAspectRatio(this.width, this.height, thumbAspect);
			this.bgSizeStyle = `${this.widthStyle * horizontalTiles}px ${this.heightStyle * verticalTiles}px`;
			this.bgImageStyle = `url(${this.thumbnailPageOne})`;
		}
		else {
			this.bgSizeStyle = '';
			this.widthStyle = this.width;
			this.heightStyle = this.height;
			this.bgImageStyle = `url(${this.thumbnailUri})`;
		}
	}

	private loadThumbnails(): Promise<void> {
		const url = this.thumbnailSheetCfg.thumbnailSheetsUri + '/1';
		if(url === this.thumbnailPageOne) {
			return Promise.resolve();
		}
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = url;
			img.onload = () => {
				this.thumbnailPageOne = url;
				resolve();
			};
			img.onerror = () => reject();
		});
	}
}

function scaleToAspectRatio(w: number, h: number, aspect: number): [number, number] {
	if (w / h > aspect) {
		return [h * aspect, h];
	}
	return [w, w / aspect];
}

