import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

export interface IProfilePicCellRendererParams extends ICellRendererParams {
	getProfilePicUrl(rowData): string;
	uiSref?: string;
	uiSrefParams?: any;
	cssClass?: string;
	getBadgeHTML(rowData): string;
}

@Component({
	selector: 'profile-picture-cell-renderer',
	host: {
		class: 'box-block height-full'
	},
	template:`
		@if (uiSref) {
			<a [ngClass]="cssClass" [uiSref]="uiSref" [uiParams]="uiSrefParams">
				<ng-container *ngTemplateOutlet="profilePic"></ng-container>
			</a>
		} @else {
			<span [ngClass]="cssClass">
				<ng-container *ngTemplateOutlet="profilePic"></ng-container>
			</span>
		}

		<ng-template #profilePic>
			@if (badgeHTML) {
				<span [innerHTML]="badgeHTML"></span>
			} @else {
				<vb-profile-picture class="margin-right-10"
					[altTxt]="'UserProfile_ProfilePicture' | translate"
					[profileUrl]="picUrl">
				</vb-profile-picture>
			}
			<span [hidden]="!userName">{{ userName }}</span>
		</ng-template>
	`,
})
export class ProfilePictureCellRendererComponent implements ICellRendererAngularComp {
	public uiSref: string;
	public uiSrefParams: (data) => { [key: string]: string };
	public picUrl: string;
	public userName: string;
	public cssClass: string;
	public badgeHTML: string;

	public agInit(params: any): void {
		this.updateCell(params);
	}

	public refresh(params: IProfilePicCellRendererParams): boolean {
		this.updateCell(params);

		return true; // nothing to do on refresh, so signal success
	}

	private updateCell(params: IProfilePicCellRendererParams): void {
		this.userName = params.valueFormatted || params.value;
		this.picUrl = params?.getProfilePicUrl?.(params.data || {});
		this.uiSref = params.uiSref;
		this.uiSrefParams = params.uiSrefParams?.(params.data || {});
		this.cssClass = params.cssClass;
		this.badgeHTML = params?.getBadgeHTML?.(params.data || {});
	}
}
