import { Component, Input, OnInit } from '@angular/core';

import { UserAuthenticationService } from 'rev-shared/security/UserAuthentication.Service';

import authLayoutStyles from 'rev-shared/ui/authLayout/VbAuthLayout.Module.less';

@Component({
	selector: 'initiate-password-reset',
	templateUrl: './InitiateResetPassword.Component.html'
})
export class InitiateResetPasswordComponent implements OnInit {
	@Input() public isChangePassword: boolean;
	@Input() public prefillUsername: string;

	public readonly authLayoutStyles = authLayoutStyles;

	public lastError: string;
	public status: any;
	public username: string;

	constructor(
		private readonly UserAuthenticationService: UserAuthenticationService
	) { }

	public ngOnInit(): void {
		if (this.prefillUsername) {
			this.username = this.prefillUsername;
		}
		this.resetStatus();
		this.status.active = true;
	}

	public submit(): void {
		this.resetStatus();
		this.status.processing = true;

		this.lastError = undefined;

		this.UserAuthenticationService.requestPasswordReset(this.username)
			.then(() => {
				this.resetStatus();
				this.status.success = true;
			}, (error: any) => {
				this.resetStatus();
				this.status.error = true;

				if (error.hasIssue('CommandDenied')) {
					this.lastError = 'InitiatePasswordReset_CommandDenied';
				}
			});
	}

	private resetStatus(): void {
		this.status = {};
	}
}
